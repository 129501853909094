/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-22 13:49:32
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    kname: 'userName',
    itemProps: {
      label: '姓名：',
      rules: [genRules(/^[\u4E00-\u9FA5]{2,4}$/, null, '请输入2-4位中文')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 20 } } },



  {
    kname: 'mobile',
    itemProps: {
      label: '手机号码：',
      rules: [genRules('phone')] },

    component: {
      name: 'el-input' } },


  {
    kname: 'email',
    itemProps: {
      label: '邮箱：',
      rules: [genRules('phone')] },

    component: {
      name: 'el-input' } }];});




// form 默认值
export var formData = function formData() {return { list: [] };};